<template>
  <div class="d-flex pointer icon">
    <svg
      width="42"
      height="22"
      viewBox="0 0 42 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="1" y1="1" x2="41" y2="1" stroke="#181818" />
      <line x1="1" y1="11" x2="41" y2="11" stroke="#181818" />
      <line x1="1" y1="21" x2="41" y2="21" stroke="#181818" />
    </svg>
  </div>
</template>
